.checkout {
    padding-top: 25px;

    .checkout__card {
        background-color: #282828;
        border-radius: 15px;
        width: 100%;
        padding: 10px;
        padding-right: 20px;
        margin-bottom: 15px;

        .image__center {
            display: flex;
            align-items: center;
            justify-content: center;

            .image {
                width: 100%;
                object-fit: cover;
                border-radius: 5px;
                padding: 3px;
                overflow: hidden;
                box-sizing: border-box;
                background-color: #cccccc;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    height: 75px;
                    object-fit: cover;
                    max-width: 100%;
                }
            }
        }

        .flex {
            display: flex;
            justify-content: space-between;

            .product__details {
                .price {
                    font-size: 16px;
                    font-weight: 600;
                    color: #ff0000;
                    text-align: right;
                    margin-bottom: 25px;
                }

                .gap {
                    display: flex;
                    gap: 15px;
                    align-items: center;

                    p {
                        margin: 0;
                        color: #fff;
                    }

                    .buttons {
                        gap: 15px;

                        button {
                            background-color: black;
                            color: white;
                            border: none;
                            padding: 7px 15px;
                            border-radius: 5px;
                            font-size: 14px;
                            font-weight: 500;
                            font-family: "Poppins", sans-serif;
                            cursor: pointer;
                            height: 33px;

                            &:hover {
                                background-color: #595959;
                            }
                        }
                    }
                }

                input {
                    border: none;
                    border-radius: 5px;
                    padding: 7px 15px;
                    width: 50px;
                    margin-right: 15px;
                    text-align: right;
                    height: 17px;
                    box-sizing: content-box;
                    background-color: #4e4e4e;
                    color: white;
                    -moz-appearance: textfield;

                    &::-webkit-inner-spin-button,
                    &::-webkit-outer-spin-button {
                        display: none;
                    }
                }

                .save {
                    background-color: black;
                    color: white;
                    border: none;
                    padding: 7px 15px;
                    border-radius: 5px;
                    font-size: 14px;
                    font-weight: 500;
                    font-family: "Poppins", sans-serif;
                    cursor: pointer;
                    height: 33px;

                    &:hover {
                        background-color: #595959;
                    }
                }
            }
        }

        .title {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
            color: #fff;
        }
    }

    .total {
        // justify-content: space-between;
        // display: flex;
        // margin-top: 35px;
        // margin-bottom: 35px;
        padding: 15px;
        background-color: #282828;
        border-radius: 15px;

        .flex {
            display: flex;
            justify-content: space-between;
            margin-top: 35px;
            align-items: center;

            p {
                margin: 0;
                color: #ff0000;
                font-weight: 600;
            }

            button {
                background-color: black;
                color: white;
                border: none;
                padding: 7px 15px;
                border-radius: 5px;
                font-size: 14px;
                font-weight: 500;
                font-family: "Poppins", sans-serif;
                cursor: pointer;
                height: 33px;

                &:hover {
                    background-color: #595959;
                }
            }
        }

        .ship {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                color: white;
                text-transform: uppercase;
                font-weight: 700;
            }

            .select-filter-stock{
                min-width: 135px;

                .app-select__menu{
                    color: #fff;
                }
            }

            select{
                color: #fff;
            }
        }
    }
}

.security__opacity {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.1);
    display: none;

    &.active {
        display: block;
    }
}

.popup {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // margin: 10px;
    // margin-left: auto;
    // margin-right: auto;
    // margin-top: 0;
    // height: calc(100vh - 330px);

    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // z-index: 1001;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    display: none;

    .panel {
        margin-top: 0;
        border-radius: 8px;
        width: 470px;
        padding: 40px 60px;
        background-color: #282828;
        font-family: "Inter", "Open Sans", Arial, Helvetica, Verdana, sans-serif;
        border-radius: 5px;
        padding: 40px 50px;
        padding-bottom: 20px;
        box-shadow: 1px 1px 7px rgba(154, 154, 204, 0.1);

        h1 {
            color: #fff;
            font-size: 20px;
            margin-bottom: 40px;
        }

        .buttons {
            display: flex;
            justify-content: end;
            gap: 15px;

            button {
                background-color: black;
                color: white;
                border: none;
                padding: 7px 15px;
                border-radius: 5px;
                font-size: 14px;
                font-weight: 500;
                font-family: "Poppins", sans-serif;
                cursor: pointer;
                height: 33px;
                text-transform: uppercase;

                &:hover {
                    background-color: #595959;
                }
            }

            .loader {
                display: inline-block;
                width: 15px;
                height: 15px;
                border: 2px solid rgba(0, 0, 0, 0);
                border-top: 2px solid #fff;
                box-sizing: border-box;
                border-radius: 50%;
                animation: loader-spin 2s linear infinite;
            }
        }
    }

    &.active {
        display: block;
    }
}
