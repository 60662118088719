.page {
    min-height: unset;
    width: 100%;
    height: calc(100% - 64px);
    margin-top: 64px;
    position: absolute;
    top: 0;
    color: #fff;

    .password-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: calc(90vh - 64px);
        margin: 10px;

        .change-password {
            width: 450px;
            background-color: #282828;
            border-radius: 5px;
            padding: 30px 40px;
            padding-bottom: 20px;

            h1 {
                font-size: 28px;
                margin-bottom: 30px;
            }

            form {
                label,
                input {
                    display: block;
                }

                label {
                    margin-bottom: 15px;
                }

                input {
                    border-radius: 3px;
                    font-family: "Inter", "Open Sans", Arial, Helvetica, Verdana, sans-serif;
                    background-color: #eee;
                    border: none;
                    padding: 12px 15px;
                    margin: 8px 0 25px 0;
                    width: 100%;
                    box-sizing: border-box;
                    outline: none;
                }

                button{
                    float: right;
                    background-color: black;
                    color: white;
                    border: none;
                    padding: 7px 15px;
                    border-radius: 5px;
                    font-size: 14px;
                    font-weight: 500;
                    font-family: "Poppins", sans-serif;
                    cursor: pointer;
                    height: 33px;

                    &:hover{
                        background-color: #595959;
                    }
                }

                .error-message{
                    color: red;
                }
            }
        }
    }
}
