.container {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;

    .row {
        width: calc(100% + 30px);
        max-width: unset;
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;

        .col-10 {
            flex: 0 0 auto;
            width: calc(10% - 30px);
            margin-left: 15px;
            margin-right: 15px;

            @media (max-width: 998px) {
                flex: 0 0 auto;
                width: calc(50% - 30px);
            }

            @media (max-width: 800px) {
                flex: 0 0 auto;
                width: calc(100% - 30px);
            }
        }

        .col-15 {
            flex: 0 0 auto;
            width: calc(15% - 30px);
            margin-left: 15px;
            margin-right: 15px;

            @media (max-width: 998px) {
                flex: 0 0 auto;
                width: calc(50% - 30px);
            }

            @media (max-width: 800px) {
                flex: 0 0 auto;
                width: calc(100% - 30px);
            }
        }

        .col-20 {
            flex: 0 0 auto;
            width: calc(20% - 30px);
            margin-left: 15px;
            margin-right: 15px;

            @media (max-width: 998px) {
                flex: 0 0 auto;
                width: calc(50% - 30px);
            }

            @media (max-width: 800px) {
                flex: 0 0 auto;
                width: calc(100% - 30px);
            }
        }

        .col-40 {
            flex: 0 0 auto;
            width: calc(40% - 30px);
            margin-left: 15px;
            margin-right: 15px;

            @media (max-width: 998px) {
                flex: 0 0 auto;
                width: calc(50% - 30px);
            }

            @media (max-width: 800px) {
                flex: 0 0 auto;
                width: calc(100% - 30px);
            }

            @media only screen and (max-width: 1030px) {
                max-width: 250px;
            }

            @media only screen and (max-width: 542px) {
                max-width: none;
            }
        }

        .col-25 {
            flex: 0 0 auto;
            width: calc(25% - 30px);
            margin-left: 15px;
            margin-right: 15px;

            @media (max-width: 998px) {
                flex: 0 0 auto;
                width: calc(50% - 30px);
            }

            @media (max-width: 800px) {
                flex: 0 0 auto;
                width: calc(100% - 30px);
            }
        }

        .col-35 {
            flex: 0 0 auto;
            width: calc(35% - 30px);
            margin-left: 15px;
            margin-right: 15px;

            @media (max-width: 998px) {
                flex: 0 0 auto;
                width: calc(50% - 30px);
            }

            @media (max-width: 800px) {
                flex: 0 0 auto;
                width: calc(100% - 30px);
            }
        }


        .col-50 {
            flex: 0 0 auto;
            width: calc(50% - 30px);
            margin-left: 15px;
            margin-right: 15px;

            @media (max-width: 998px) {
                flex: 0 0 auto;
                width: calc(50% - 30px);
            }

            @media (max-width: 800px) {
                flex: 0 0 auto;
                width: calc(100% - 30px);
            }
        }

        .col-60 {
            flex: 0 0 auto;
            width: calc(60% - 30px);
            margin-left: 15px;
            margin-right: 15px;

            @media (max-width: 998px) {
                flex: 0 0 auto;
                width: calc(50% - 30px);
            }

            @media (max-width: 800px) {
                flex: 0 0 auto;
                width: calc(100% - 30px);
            }
        }

        .col-65 {
            flex: 0 0 auto;
            width: calc(65% - 30px);
            margin-left: 15px;
            margin-right: 15px;

            @media (max-width: 998px) {
                flex: 0 0 auto;
                width: calc(50% - 30px);
            }

            @media (max-width: 800px) {
                flex: 0 0 auto;
                width: calc(100% - 30px);
            }
        }

        .col-55 {
            flex: 0 0 auto;
            width: calc(55% - 30px);
            margin-left: 15px;
            margin-right: 15px;

            @media (max-width: 998px) {
                flex: 0 0 auto;
                width: calc(50% - 30px);
            }

            @media (max-width: 800px) {
                flex: 0 0 auto;
                width: calc(100% - 30px);
            }
        }

        .col-75 {
            flex: 0 0 auto;
            width: calc(75% - 30px);
            margin-left: 15px;
            margin-right: 15px;

            @media (max-width: 998px) {
                flex: 0 0 auto;
                width: calc(50% - 30px);
            }

            @media (max-width: 800px) {
                flex: 0 0 auto;
                width: calc(100% - 30px);
            }
        }

        .col-80 {
            flex: 0 0 auto;
            width: calc(80% - 30px);
            margin-left: 15px;
            margin-right: 15px;

            @media (max-width: 998px) {
                flex: 0 0 auto;
                width: calc(50% - 30px);
            }

            @media (max-width: 800px) {
                flex: 0 0 auto;
                width: calc(100% - 30px);
            }
        }

        .col-85 {
            flex: 0 0 auto;
            width: calc(85% - 30px);
            margin-left: 15px;
            margin-right: 15px;

            @media (max-width: 998px) {
                flex: 0 0 auto;
                width: calc(50% - 30px);
            }

            @media (max-width: 800px) {
                flex: 0 0 auto;
                width: calc(100% - 30px);
            }
        }

        .col-90 {
            flex: 0 0 auto;
            width: calc(90% - 30px);
            margin-left: 15px;
            margin-right: 15px;

            @media (max-width: 998px) {
                flex: 0 0 auto;
                width: calc(50% - 30px);
            }

            @media (max-width: 800px) {
                flex: 0 0 auto;
                width: calc(100% - 30px);
            }
        }
    }
}