html {
    body {
        .container {
            .title-container {
                margin-bottom: 25px;
                font-size: 16px;
                display: inline-block;
                width: 100%;

                h1 {
                    font-size: 18px;
                    font-weight: 500;

                    @media only screen and (max-width: 740px) {
                        margin-bottom: 15px;
                        margin-top: 15px;
                        width: 100%;
                    }
                }

                @media only screen and (max-width: 760px) {
                    display: block;
                }
            }

            .select {                
                background-color: #282828;

                .app-select__control{
                    background-color: #4e4e4e;
                    border: none;

                    &--is-focused {
                        box-shadow: none;
                    }

                    .app-select__placeholder {
                        color: white;
                    }
                }

                .app-select__single-value,
                .app-select__input-container {
                    color: white;
                }

                .app-select__indicators{
                    background-color: #4e4e4e;
                    border-radius: 5px;
                }

                .app-select__menu {
                    background-color: #161616;

                    .app-select__option {
                        &--is-focused {
                            background-color: #4e4e4e;
                        }

                        &--is-selected {                            
                            background-color: #c50000;
                        }
                    }
                }
            }

            .pagination {
                text-align: right;
                margin-bottom: 15px;

                a {
                    box-sizing: content-box;
                    margin-left: 3px;
                    margin-right: 3px;
                    height: 25px;
                    display: inline-block;
                    text-align: center;
                    text-decoration: none;
                    padding-top: 8px;
                    font-size: 13px;
                    border-radius: 25px;
                    min-width: 12px;
                    padding-left: 10px;
                    padding-right: 10px;
                    background-color: black;
                    color: white;
                    cursor: pointer;

                    &.active {
                        background-color: #9f0000;
                        color: white;
                    }
                }

                span {
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }
        }
    }
}