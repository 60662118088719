@import "../../styles/variables.scss";

.orders {
    padding-top: 25px;
    font-size: 14px;

    .orders-container {
        overflow-x: auto;
        background-color: #282828;
        color: #b7b7b7;
        padding: 20px 15px;
        border-radius: 5px;
        margin-bottom: 25px;
        box-shadow: 1px 1px 7px rgba(154, 154, 204, 0.1);

        table {
            width: 100%;
            border-collapse: collapse;

            th {
                font-size: 14px;
                font-weight: 500;
                text-align: left;
                padding: 10px 5px 10px 5px;

                &:last-child{
                    text-align: right;
                }
            }

            tr:not(:first-child) {
                border-top: 2px solid #3b3b3b;

                th,
                td {
                    text-align: left;
                    padding: 10px 5px 10px 5px;

                    &:last-child {
                        text-align: right;
                    }
                }

                

                .status {
                    font-size: 12px;
                    font-weight: 500;
                    padding: 3px 10px;
                    border-radius: 3px;

                    &.status-12 {
                        background-color: #12934b;
                        color: #ffffff;
                    }

                    &.status-10,
                    &.status-11 {
                        background-color: #ff1515;
                        color: #ffffff;
                    }

                    &.status-2 {
                        background-color: #ff7d00;
                        color: #ffffff;
                    }

                    &.status-1 {
                        background-color: #008ebd;
                        color: #ffffff;
                    }

                    &.status-3 {
                        background-color: #008ebd;
                        color: #ffffff;
                    }
                }

                a {
                    color: #b7b7b7;
                }
            }

            th:nth-child(1), 
            td:nth-child(1) {
                width: 150px;
            }

            th:nth-child(2), 
            td:nth-child(2) {
                width: 150px;
            }

            @media only screen and (max-width: 620px) {
                width: 620px;
            }
        }
    }
}