li.slide {
    width: 100%;

    >div.news-item {
        border-radius: 5px;
        position: relative;
        max-height: 300px;
        height: 300px;

        p {
            margin: 0;

            span {
                font-weight: 500;
                margin-right: 6px;
            }
        }

        .close {
            display: inline-block;
            position: absolute;
            right: 15px;
            top: 15px;
            cursor: pointer;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-position: center center;
        }

        >div {
            position: absolute;
            display: inline-block;
            right: 0;
            height: 100%;

            span {
                text-align: center;
                margin-top: 80px;
                text-transform: uppercase;
                color: #fff;
                background-color: #da2f10;
                padding: 30px 40px;
                font-size: 38px;
                display: inline-block;
                position: absolute;
                left: 0px;
                font-weight: 600;
            }
        }
    }
}