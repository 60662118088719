*{
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
} 

body{
  margin: 0;
  padding: 0;
}

#root{
  position: relative;
  min-height: 100vh;
}