@import "../../styles/variables.scss";

.products {
    padding-top: 10px;

    .title-container {

        .title{
            display: inline-block;
            width: calc(100% - 515px);
            text-align: left;
            vertical-align: top;

            h1 {                
                margin-top: 5px;
            }

            @media only screen and (max-width: 880px) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 10px;
                margin-top: 0;

                h1{
                    margin: 0;
                }
            }
        }
        // h1 {
        //     display: inline-block;
        //     width: calc(100% - 580px);
        //     text-align: left;
        //     vertical-align: top;
        // }

        span {
            display: inline-block;
            margin-right: 15px;
        }

        .filter-category,
        .filter-stock,
        .sort,
        .sort-direction {
            display: inline-block;
            vertical-align: top;

            @media only screen and (max-width: 740px) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        .mobile{
            display: none;

            @media only screen and (max-width: 880px) {
                display: block;
                margin-right: 15px !important;
                padding-top: 0 !important;
            }
        }

        .desktop{
            @media only screen and (max-width: 880px) {
                display: none;
            }
        }

        .filter-category,
        .filter-stock,
        .sort {
            margin-right: 15px;

            @media only screen and (max-width: 880px) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-right: 0;
                margin-bottom: 15px;
            }
        }

        .select-filter-stock,
        .select-sort {
            width: 130px;
            display: inline-block;
            text-align: left;
            font-size: 14px;
        }

        .first{
            width: 200px;
        }

        .select-filter-stock {
            @media only screen and (max-width: 740px) {
                width: 100%;
            }
        }

        .select-sort {
            @media only screen and (max-width: 740px) {
                width: calc(100% - 62px);
            }
        }

        .select{
            @media only screen and (max-width: 880px) {
                width: calc(100% - 92px);
            }
        }

        .sort-direction {
            font-size: 16px;
            margin-left: 15px;
            margin-right: 10px;
            padding-top: 9px;
            cursor: pointer;

            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
        }
    }

    .categories{
        margin-bottom: 25px;
        
        .category-item {
            background-color: black;
            display: inline-block;
            border-radius: 5px;
            padding: 8px 25px;
            color: white;
            margin-right: 25px;
            cursor: pointer;

            &:hover{
                background-color: rgb(27, 27, 27);
            }

            &.active {                      
                background-color: #c50000;
            }

            .content{                
                display: flex;
                justify-content: center;
                align-items:center;

                svg {
                    margin-left: 15px;
                    margin-top: -1px;
                }
            }
        }
    }

    .products-container {
        max-width: unset;
        display: flex;
        flex-wrap: wrap;

        .row {
            width: calc(100% + 50px);
            margin-left: -25px;
            margin-right: -25px;
        }

        @media only screen and (max-width: 800px) {
            justify-content: space-between;
        }

        @media only screen and (max-width: 570px) {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
        }

        @media only screen and (max-width: 1030px) {
            justify-content: center;
        }

        @media only screen and (max-width: 590px) {
            justify-content: space-between;
        }
    }

    @media only screen and (max-width: 740px) {
        padding-top: 10px;
    }
}
