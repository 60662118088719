html body footer {
    p {
        color: #9b9b9b;
        font-size: 14px;
        padding-top: 0px;
        padding-bottom: 0px;
        width: 100%;

        span{
            float: right;
        }
    }
}