@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "../../styles/variables.scss";
@import "../../styles/grid.scss";
@import "../../styles/toast.scss";
@import "../../styles/app.scss";

html {
    body {
        background-color: #efefef;
        background-image: url("../../assets/images/bg.jpg");
        font-family: $font-poppins;
        position: relative;
        min-height: 100vh;

        .container {
            .card {
                background: #fff;
                border-radius: 5px;
                box-shadow: 1px 1px 7px rgba(154, 154, 204, 0.2);
                // border: 1px solid #dfdfdf;
                padding: 14px 18px;
            }

            h1 {
                margin: 0;
                font-size: 18px;
                font-weight: 500;
            }

            .title-container {
                background-color: #282828;
                color: white;
                padding: 15px 25px;
                border-radius: 5px;
                display: flex;
                justify-content: space-between;

                button {
                    background-color: black;
                    color: white;
                    border: none;
                    padding: 7px 15px;
                    border-radius: 5px;
                    font-size: 14px;
                    font-weight: 500;
                    font-family: "Poppins", sans-serif;
                    cursor: pointer;
                    height: 33px;

                    &:hover{
                        background-color: #595959;
                    }
                }
            }
        }

        footer {
            background-color: #0c0d0e;
            position: absolute;
            width: 100%;
            bottom: 0;

            p {
                padding: 0;
                box-sizing: border-box;
                display: inline-block;
            }
        }
    }
}

.layout {
    padding-bottom: 50px;
}

.empty,
.loading {
    background-color: #282828;
    color: white;
    padding: 10px 25px;
    border-radius: 5px;
    margin-bottom: 25px;
    box-shadow: 1px 1px 7px rgba(154, 154, 204, 0.1);
    font-size: 16px;
}
