@import "../../styles/variables.scss";

.breadcrumb {
    font-size: 14px;
    display: inline-block;
    color: #ffffff;

    a {
        text-decoration: none;
        color: #ffffff;;
        vertical-align: top;
    }

    span {
        display: inline-block;
        vertical-align: top;

        &.separator {
            font-size: 20px;
            margin-left: 5px;
            margin-right: 5px;
            margin-top: -3px;
        }

        &.current {}
    }
}

.order {
    padding-top: 25px;
    font-size: 14px;

    .title-container {
        margin-top: 10px;
    }

    .order-container {
        max-width: unset;
        display: flex;
        flex-wrap: wrap;
        background-color: #282828;
        color: #b7b7b7;
        padding: 20px 15px;
        border-radius: 5px;
        margin-bottom: 25px;
        box-shadow: 1px 1px 7px rgba(154, 154, 204, 0.1);
        overflow-x: auto;

        .details {
            >label {
                margin-bottom: 9px;
                display: inline-block;
                font-family: "Inter", sans-serif;
                display: inline-block;
                margin: 0;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 25px;
            }

            div {
                margin-bottom: 3px;

                label {
                    font-weight: 500;
                    font-weight: 500;
                    margin-right: 15px;
                    display: inline-block;
                    width: 100px;
                    margin-bottom: 0px;
                }
            }
        }

        h2 {
            display: inline-block;
            width: 100%;
            font-size: 16px;
            font-weight: 500;
            margin-top: 25px;
        }

        table.products {
            border-collapse: collapse;
            min-width: 500px;
            width: 100%;

            thead,
            tbody,
            tfoot {
                tr {
                    border-bottom: 2px solid #3b3b3b;
                    height: 40px;

                    th {
                        font-weight: 600;
                    }

                    th,
                    td {
                        padding: 7px 15px;
                        font-size: 13px;
                        white-space: nowrap;

                        &:nth-child(1) {
                            width: 100px;
                            text-align: left;
                            padding-left: 10px;
                        }

                        &:nth-child(2) {
                            width: unset;
                            text-align: left;
                        }

                        &:nth-child(3) {
                            width: 100px;
                            text-align: center;
                            position: relative;
                            padding-right: 20px;
                        }

                        &:nth-child(4) {
                            width: 100px;
                            text-align: center;
                        }

                        &:nth-child(5) {
                            width: 100px;
                            text-align: center;
                            font-weight: 600;
                        }

                        &:nth-child(6) {
                            width: 100px;
                            text-align: center;
                        }

                        &:nth-child(7) {
                            width: 50px;
                            text-align: center;
                        }
                    }

                    td {
                        &:nth-child(3) {
                            text-align: right;
                        }

                        &:nth-child(4) {
                            text-align: right;
                        }

                        &:nth-child(5) {
                            text-align: right;
                        }

                        &:nth-child(6) {
                            text-align: right;
                        }
                    }
                }
            }

            thead {
                tr {
                    background-color: #3b3b3b;
                    border: none;
                }
            }

            tfoot {
                tr {
                    font-weight: 600;
                    border: none;

                    td {
                        &:nth-child(2) {
                            width: unset;
                            text-align: right;
                        }

                        &:nth-child(3) {
                            text-align: right;
                        }

                        &:nth-child(4) {
                            text-align: right;
                        }

                        &:nth-child(5) {
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
}