@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
    body.login {
        background-color: #e8e8e8;
        background-image: none;
        overflow-y: hidden;

        .page {
            width: 100%;
            height: calc(100vh - 20px);

            >.container {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 10px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 0;
                height: calc(100vh - 70px);

                .login {
                    margin-top: 0;
                    border-radius: 8px;
                    width: 470px;
                    padding: 40px 60px;
                    background-color: #fff;
                    font-family: "Inter", "Open Sans", Arial, Helvetica, Verdana, sans-serif;
                    border-radius: 5px;
                    padding: 40px 50px;
                    padding-bottom: 20px;
                    box-shadow: 1px 1px 7px rgba(154, 154, 204, 0.1);
                    border: 1px solid #ddd;

                    @media (max-width: 520px) {
                        padding: 40px 30px;
                        width: 100%;
                        margin-top: 0;
                    }

                    img {
                        height: 60px;
                        margin-bottom: 24px;

                        @media only screen and (max-width: 460px) {
                            height: 45px;
                        }
                    }

                    h1 {
                        letter-spacing: -1px;
                        color: black;
                        margin: 0;
                        font-weight: 600;
                        font-size: 20px;
                        margin-bottom: 0px;

                        @media only screen and (max-width: 460px) {
                            font-size: 20px;
                        }
                    }

                    p {
                        @media only screen and (max-width: 460px) {
                            font-size: 14px;
                        }
                    }

                    .sign-in {
                        font-weight: 700;
                        padding-top: 32px;
                        margin: 0;
                    }

                    .panel {
                        padding-top: 26px;

                        .form-group {
                            input {
                                border-radius: 3px;
                                font-family: "Inter", "Open Sans", Arial, Helvetica, Verdana, sans-serif;
                                background-color: #eee;
                                border: none;
                                padding: 12px 15px;
                                margin: 8px 0;
                                width: 100%;
                                box-sizing: border-box;

                                &:focus {
                                    outline: none;
                                }

                                &::placeholder {
                                    color: #b5b5b5;
                                    font-size: 14px;
                                    font-family: "Inter", "Open Sans", Arial, Helvetica, Verdana, sans-serif;
                                }
                            }

                            .validation-message {
                                min-height: 24px;
                                line-height: 24px;
                                color: #d50000;
                                text-align: left;
                                margin-bottom: -2px;
                            }
                        }

                        .button-container {
                            text-align: center;

                            button {
                                width: 100%;
                                margin-top: 10px;
                                margin-bottom: 20px;
                                border-radius: 5px;
                                border: 1px solid #c1181c;
                                background-color: #c1181c;
                                color: #ffffff;
                                font-size: 13px;
                                letter-spacing: 1px;
                                transition: transform 80ms ease-in;
                                padding: 10px 25px;
                                cursor: pointer;
                                position: relative;
                                transition: 0.5s;
                                height: 38px;

                                &:hover {
                                    color: #fff;
                                    background-color: #930407;
                                    border-color: #930407;
                                    transition: 0.5s;
                                }

                                &.loading {
                                    //padding-left: 50px;
                                    background: #930407;
                                }

                                .loader {
                                    display: inline-block;
                                    left: calc(50% - 10px);
                                    width: 20px;
                                    height: 20px;
                                    border: 2px solid rgba(0, 0, 0, 0);
                                    border-top: 2px solid #fff;
                                    box-sizing: border-box;
                                    border-radius: 50%;
                                    animation: loader-spin 2s linear infinite;
                                    position: absolute;
                                    top: 8px;
                                }

                                @keyframes loader-spin {
                                    0% {
                                        transform: rotate(0deg);
                                    }

                                    100% {
                                        transform: rotate(360deg);
                                    }
                                }
                            }

                            .disabled {
                                background-color: #930407;
                                cursor: default;
                            }
                        }

                        a.forget-password {
                            text-align: right;
                            display: inline-block;
                            width: 100%;
                            font-size: 15px;
                            margin-bottom: 5px;
                        }
                    }
                }
            }

            .Toastify__toast-container {
                width: 450px;

                .Toastify__toast-body {
                    color: black;
                }

                .Toastify__close-button {
                    align-self: auto;
                }

                @media only screen and (max-width: 480px) {
                    width: calc(100% - 40px);
                    left: 20px;
                    right: 20px;
                    bottom: 15px;
                }
            }
        }

        .error-message {   
            margin-top: 0;
            font-size: 14px;
            color: #d50000;
            font-weight: 400;
        }

        .login-failed{
            margin-top: -15px;
        }
    }
}