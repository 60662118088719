.product {
    flex: 0 0 auto;
    width: calc(50%);
    padding: 10px 20px;
    width: calc(50% - 20px);
    display: flex;
    border: none;
    background-color: #282828;
    border-radius: 5px;
    margin-bottom: 25px;
    box-shadow: 1px 1px 7px rgba(158, 158, 158, .1);
    color: white;

    &:nth-child(odd) {
        margin-right: 40px;

        // @media only screen and (max-width: 590px) {
        //     margin-right: 40px !important;
        // }

        @media only screen and (max-width: 1030px) {
            margin-right: 0;
        }        
    }

    .image {
        width: 100%;
        height: 285px;
        object-fit: cover;
        border-radius: 5px;
        padding: 3px;
        overflow: hidden;
        box-sizing: border-box;
        background-color: #cccccc;
        cursor: pointer;

        &.placeholder img {
            object-fit: cover;
            cursor: default;
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: 3px;
            object-fit: contain;
        }
    }

    .title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;

        @media only screen and (max-width: 1030px) {
            margin-top: 15px;
        }
    }

    .info {

        .sku,
        .brand {
            font-size: 14px;

            span {
                font-weight: 500;
            }
        }
    }

    .stock {
        position: absolute;
        // bottom: 42px;

        .title {
            font-size: 14px;
            font-style: italic;
            margin-bottom: 0;
            margin-top: 10px;
            font-weight: 400;
        }

        .pre-ordered,
        .shipped,
        .remaining,
        .in-stock {
            font-size: 14px;

            span {
                font-weight: 500;
            }
        }

        // @media only screen and (max-width: 997px) {
        //     margin-bottom: 100px;
        //     position: initial;
        // }

        // @media only screen and (max-width: 934px) {
        //     margin-bottom: 150px;
        // }

        @media only screen and (max-width: 800px) {
            position: initial;
            // margin-bottom: 100px;
        }

        @media only screen and (max-width: 590px) {
            position: initial;
            // margin-bottom: 100px;
        }

        @media only screen and (max-width: 570px) {
            margin-bottom: 0;
        }
    }

    .row{
        @media only screen and (max-width: 800px) {
            flex-wrap: nowrap;
        }

        @media only screen and (max-width: 542px) {
            display: block;
        }
    }

    .row .col-60 {
        margin-left: 5px;

        @media only screen and (max-width: 1030px) {
            width: calc(100% - 305px);
        }

        @media only screen and (max-width: 800px) {
            margin-left: 5px;
            margin-right: 20px;
            width: calc(100% - 315px);
        }

        @media only screen and (max-width: 542px) {
            width: calc(100% - 35px);
            margin-left: 20px;
        }
    }

    .col-60 {
        position: relative;

        .add-to-cart-container {
            position: absolute;
            right: 0;
            bottom: 0;

            .price-original {
                font-size: 16px;
                font-weight: 400;
                text-align: right;
                margin-top: 25px;
                color: #adadad;
                text-decoration: line-through;
            }

            .price {
                font-size: 16px;
                font-weight: 600;
                text-align: right;
                color: #ff0000;

                // @media only screen and (max-width: 570px) {
                //     margin-bottom: 15px;
                // }
            }

            .responsive-button {
                display: flex;
                justify-content: center;
                align-items:center;

                @media only screen and (max-width: 997px) {
                    text-align: right;
                    // display: flex;
                    // justify-content: space-between;
                }

                @media only screen and (max-width: 934px) {
                    display: block;
                }
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            input[type=number] {
                -moz-appearance: textfield;
            }

            input {
                border: none;
                border-radius: 5px;
                padding: 7px 15px;
                width: 50px;
                margin-right: 15px;
                text-align: right;
                height: 17px;
                box-sizing: content-box;
                background-color: #4e4e4e;
                color: white;

                // @media only screen and (max-width: 997px) {
                //     margin-right: 0;
                // }

                // @media only screen and (max-width: 800px) {
                //     margin-right: 15px;
                // }

                // @media only screen and (max-width: 934px) {
                //     margin-bottom: 5px;
                // }
            }

            button {
                background-color: black;
                color: white;
                border: none;
                padding: 7px 15px;
                border-radius: 5px;
                font-size: 14px;
                font-weight: 500;
                font-family: "Poppins", sans-serif;
                cursor: pointer;
                height: 33px;

                &:hover {
                    background-color: #595959;
                }
            }

            @media only screen and (max-width: 996px) {
                width: 100%;
            }

            @media only screen and (max-width: 570px) {
                // position: initial;
                margin-top: 20px;
                float: right;
            }

            @media only screen and (max-width: 420px) {
                position: initial;
                margin-top: 20px;
                float: right;
            }
        }
    }

    @media only screen and (max-width: 800px) {
        &:nth-child(odd) {
            margin-right: 0;
        }
    }

    // @media only screen and (max-width: 570px) {
    //     width: 100%;
    // }

    @media only screen and (max-width: 1030px) {
        width: 100%;
    }

    @media only screen and (max-width: 590px) {
        min-width: auto;
    }

    @media only screen and (max-width: 570px) {
        width: 100%;
    }
}