.news {
    padding-top: 15px;
    padding-bottom: 15px;
    background-image: linear-gradient(#505050 0%, 95%, #ffffff00 100%);

    .carousel-root {
        border-radius: 5px;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 1px 1px 7px rgba(158, 158, 158, .1);
        
        .carousel-custom-arrow {
            color: rgb(255, 255, 255);
            background: none;
            border: none;
            cursor: pointer;
            width: 48px;
            height: 48px;
            display: inline-block;
            border-radius: 50%;
            top: calc(50% - 30px);
            background-color: #6C7476;

            &:hover {
                background-color: #292929;
            }

            span {
                font-size: 42px;
                margin-top: 2px;
            }
        }

        .carousel-prev-arrow {
            position: absolute;
            z-index: 3;
            left: 15px;

            span {
                margin-left: -4px;
            }
        }

        button.carousel-next-arrow {
            position: absolute;
            z-index: 3;
            right: 15px;

            span {}
        }

        .control-dots .dot {
            box-shadow: none;
            border: 1px solid #b1b1b1;
            height: 11px;
            width: 11px;
        }
    }  
}