@import "../../styles/variables.scss";

header {
    width: 100%;
    background-color: transparent;
    transition: 0.3s;
    background-color: #000000;
    padding-top: 5px;
    padding-bottom: 5px;

    .container {
        height: 100%;
        display: flex;
        // flex-wrap: wrap;
        justify-content: space-between;

        // @media only screen and (max-width: 620px) {
        //     display: block;
        // }

        > .row {
            height: 100%;

            > div {
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    > .search {
        .container {
            align-items: center;

            .logo {
                display: flex;

                @media only screen and (max-width: 620px) {
                    display: block;
                }

                a {
                    display: flex;
                    align-items: center;
                    text-decoration: none;

                    img {
                        height: 40px;
                        margin-right: 15px;
                    }

                    span {
                        font-weight: 500;
                        color: rgb(255, 255, 255);
                        margin-right: 30px;
                        font-size: 18px;

                        @media only screen and (max-width: 750px) {
                            display: none;
                        }
                    }
                }
            }

            .search-container {
                border-radius: 5px;
                font-family: "Inter", "Open Sans", Arial, Helvetica, Verdana, sans-serif;
                // border: 1px solid #484848;
                border-radius: 20px;
                padding: 8px 20px;
                margin: 8px 0;
                width: 40%;
                margin-left: -110px;
                height: 38px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #323232;

                @media only screen and (max-width: 750px) {
                    margin-left: auto;
                }

                @media only screen and (max-width: 620px) {
                    width: 100%;
                    margin: 15px 0;
                }

                form {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                }

                input {
                    margin-top: 0px;
                    background-color: transparent;
                    border: none;
                    width: calc(100% - 20px);
                    font-size: 13px;
                    height: 20px;
                    color: white;
                    font-family: "Poppins", sans-serif;
                    font-size: 15px;
                    font-family: "Inter", "Open Sans", Arial, Helvetica, Verdana, sans-serif;
                    font-weight: 500;

                    &:focus {
                        outline: none;
                    }

                    &::placeholder {
                        color: #b5b5b5;
                        font-size: 15px;
                        font-family: "Inter", "Open Sans", Arial, Helvetica, Verdana, sans-serif;
                        font-weight: 500;
                    }
                }

                .close {
                    display: inline-block;
                    color: #b5b5b5;
                    cursor: pointer;
                    font-size: 17px;
                    margin-right: -10px;
                    height: 30px;
                    width: 30px;
                    text-align: center;
                    border-radius: 18px;

                    &:hover {
                        // background-color: #505050;
                    }

                    svg {
                        margin-top: 7px;
                    }
                }

                .search {
                    display: flex;
                    margin-bottom: 3px;
                    margin-left: -5px;
                    margin-right: 7px;
                    color: #d7d7d7;
                    // cursor: pointer;

                    svg {
                        height: 20px;
                        width: 20px;
                        margin-top: 7px;
                    }
                }

                @media only screen and (max-width: 750px) {
                    width: 100%;
                }
            }

            // button {
            //     margin: 0;
            //     background-color: $color-primary;
            //     display: inline-block;
            //     width: 100px;
            //     margin-left: 15px;
            //     color: white;
            //     border: none;
            //     padding: 7px 15px;
            //     border-radius: 5px;
            //     font-size: 14px;
            //     font-weight: 500;
            //     font-family: "Poppins", sans-serif;
            //     cursor: pointer;
            //     height: 36px;

            //     &:hover {
            //         background-color: $color-primary-dark;
            //     }

            //     // @media only screen and (max-width: 620px) {
            //     //     margin-left: 0;
            //     //     width: 100%;
            //     // }

            //     @media only screen and (max-width: 620px) {
            //         display: none;
            //     }
            // }
        }

        .monogram {
            background-color: #c1181c;
            width: 35px;
            height: 35px;
            text-align: center;
            border-radius: 50%;
            color: white;
            font-size: 14px;
            line-height: 34px;
            margin-left: 25px;
        }
    }

    .nav {
        width: 100%;
        height: 55px;
        background-color: $color-primary-menu;

        .primary {
            width: calc(100% - 80px - 21px);
            display: flex;
            align-items: center;

            a {
                color: white;
                font-size: 15px;
                font-weight: 500;
                padding-bottom: 5px;
                padding-top: 5px;
                padding-left: 10px;
                padding-right: 10px;
                margin-left: 5px;
                margin-right: 5px;
                cursor: pointer;
                display: inline-block;
                text-decoration: none;
                position: relative;

                &:first-child {
                    padding-left: 0;
                    margin-left: 0px;

                    &::after {
                        left: 0px;
                    }

                    &.active::after,
                    &:hover::after {
                        width: calc(100% - 10px);
                    }
                }

                &::after {
                    content: "";
                    width: 20px;
                    height: 2px;
                    display: inline-block;
                    background-color: white;
                    position: absolute;
                    left: 10px;
                    bottom: 0;
                    width: 0px;
                    transition: all 150ms ease 0s;
                }

                &.active::after,
                &:hover::after {
                    width: calc(100% - 20px);
                }
            }

            @media only screen and (max-width: 620px) {
                width: 100%;
                margin-top: 10px;
                margin-bottom: 15px;
            }
        }

        @media only screen and (max-width: 620px) {
            height: auto;
        }
    }

    .secondary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        font-size: 15px;
        font-weight: 500;

        a {
            font-size: 22px;
            box-sizing: border-box;
            width: 50px;
            text-align: center;
            cursor: pointer;
            position: relative;
            margin-top: -1px;
            height: calc(100% - 15px);
            border-radius: 5px;
            padding: 5px;
            padding-top: 7px;
            color: #fff;

            svg{
                margin-top: 5px;
            }

            &:hover{
                background-color: #323232;
            }
        }

        .cart {
            border-radius: 5px;
            box-sizing: border-box;
            cursor: pointer;
            font-size: 22px;
            height: calc(100% - 15px);
            margin-top: -1px;
            padding: 7px 5px 5px;
            position: relative;
            text-align: center;
            width: 50px;

            svg {
                margin-top: 5px;
            }

            &:hover {
                background-color: #323232;
            }
        }

        .profile {
            font-size: 22px;
            box-sizing: border-box;
            width: 50px;
            text-align: center;
            cursor: pointer;
            position: relative;
            margin-top: -1px;
            height: calc(100% - 15px);
            border-radius: 5px;
            padding: 5px;
            padding-top: 7px;

            svg {
                margin-top: 5px;
            }

            &:hover,
            &.active {
                background-color: #323232;
            }

            .profile-dropdown {
                position: absolute;
                color: black;
                font-size: 14px;
                width: 200px;
                width: 250px;
                right: 0;
                z-index: 10;
                border-radius: 5px;
                top: 56px;
                border-top: 0;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                box-shadow: 1px 1px 7px rgba(255, 255, 255, 0.3);
                background-color: #282828;
                color: white;

                .avatar {
                    // width: 50px;
                    height: 50px;
                    margin-top: 40px;
                    margin-bottom: 25px;
                    display: inline-block;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .details {
                    margin-top: 25px;
                    
                    .name,
                    .country {
                        text-wrap: nowrap;
                        overflow: hidden;
                        display: inline-block;
                        width: 100%;
                        text-align: left;
                        padding: 3px 10px;
                        box-sizing: border-box;
                        text-align: center;
                    }

                    .name {
                        top: 5px;
                    }

                    .country {
                        margin-top: -15px;
                        font-weight: 400;
                        font-size: 14px;
                        top: 40px;
                    }
                }

                ul {
                    margin: 0;
                    padding: 0;
                    padding-top: 25px;
                    padding-bottom: 40px;
                    list-style-type: none;

                    li {
                        text-align: center;

                        .my-orders,
                        .change-password {
                            color: white;
                            text-decoration: underline;
                            margin-bottom: 15px;
                            display: inline-block;
                            font-size: 14px;
                            width: 100%;
                        }

                        .logout {
                            background-color: black;
                            color: white;
                            border: none;
                            padding: 7px 15px;
                            border-radius: 5px;
                            font-size: 13px;
                            font-weight: 400;
                            font-family: "Poppins", sans-serif;
                            cursor: pointer;
                            box-sizing: border-box;
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 620px) {
            justify-content: end;
        }
    }

    &.sticky {
        position: fixed;
        top: 0;
        background-color: #fff;
        z-index: 10;

        .main-header {
            height: 60px;
        }
    }
}
