.Toastify__toast-body {
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    color: black;

    > div:nth-child(2) {
        margin-top: -2px;
    }
}

.Toastify__toast--error {
    svg {
        fill: #cc2427;
    }

    .Toastify__progress-bar--error {
        background: #cc2427;
        background-color: #cc2427;
    }
}

body {
    .Toastify__toast-container {
        width: 400px;

        .Toastify__toast{
            @media only screen and (max-width: 480px) {
                margin-bottom: 15px;
            }
        }

        @media only screen and (max-width: 480px) {
            width: calc(100% - 30px);
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 30px;
        }
    }
}
