.page-503 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;

    .no-service {
        text-align: center;

        h1 {
            font-size: 32px;
            margin-top: 75px;
            margin-bottom: 25px;
        }

        p {
            margin-top: 25px;
            margin-bottom: 50px;
        }
    }
}